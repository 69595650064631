import React, { useState, useEffect } from "react";
import "./Consent.css";
import moment from "moment";
import Common from "./Common";

export default function Consent(props: any) {
  const appName = "Axis";
  const action = "Confirm";

  const [dropdownClass, setDropdownClass] = useState("dd");
  const [dropdownSelection, setDropdownSelection] = useState("List of Banks");
  const [accessUntil, setAccessUntil] = useState();

  useEffect(() => {
    var currentDate = moment();
    var until = moment(currentDate)
      .add(6, "M")
      .toString();
    setAccessUntil(until);
  }, []);

  const makeDropdownSelection = (selection: string) => {
    setDropdownSelection(selection);
    dropdownChevronToggle();
  };

  const dropdownChevronToggle = () => {
    if (dropdownClass === "dd") {
      setDropdownClass("dd active");
    } else {
      setDropdownClass("dd");
    }
  };

  return (
    <Common>
      <div className='card'>
        <span>
          <img
            alt='app'
            className='bank-icon'
            src='favicon.ico'
            height='32'
            width='32'
          />
        </span>
        <h1 className='card-header'>{appName}</h1>
        <hr />
        <div className='md-stepper-horizontal blue'>
          <div className='md-step active'>
            <div className='md-step-circle'>
              <span>1</span>
            </div>
            <div className='md-step-title'>Consent</div>
            <div className='md-step-bar-left'></div>
            <div className='md-step-bar-right'></div>
          </div>
          <div className='md-step'>
            <div className='md-step-circle'>
              <span>2</span>
            </div>
            <div className='md-step-title'>Authenticate</div>
            <div className='md-step-bar-left'></div>
            <div className='md-step-bar-right'></div>
          </div>
          <div className='md-step'>
            <div className='md-step-circle'>
              <span>3</span>
            </div>
            <div className='md-step-title'>Authorize</div>
            <div className='md-step-bar-left'></div>
            <div className='md-step-bar-right'></div>
          </div>
        </div>
        <hr />
        <div className='card-body'>
          <form className='form-signin' action='/' method='GET'>
            <label className='consent-label'>Bank Selection</label>
            <p id='consent-info' className='consent-info-card'>
              Which bank would you like to connect to?
            </p>
            <div className='dropdown'>
              <button
                className='btn btn-default dropdown-toggle'
                type='button'
                id='dropdownMenu1'
                data-toggle='dropdown'
                aria-haspopup='false'
                aria-expanded='false'
                onClick={dropdownChevronToggle}
              >
                <div className={dropdownClass}>{dropdownSelection}</div>
              </button>
              <ul className='dropdown-menu' aria-labelledby='dropdownMenu1'>
                <li>
                  <a
                    onClick={() => {
                      makeDropdownSelection("OpenATB Sandbox");
                    }}
                  >
                    OpenATB Sandbox
                  </a>
                </li>
              </ul>
            </div>

            <div className='control-group' style={{ marginBottom: "20px" }}>
              <label className='consent-label'>Consent</label>
              <p id='consent-info' className='consent-info-card'>
                In order for {appName} to offer this service, we need your
                approval to access the following information from the accounts
                you hold at your bank.
              </p>
              <div
                className='panel-group'
                id='accordion'
                role='tablist'
                aria-multiselectable='true'
              >
                <div className='panel panel-default'>
                  <div className='panel-heading-all' role='tab' id='headingAll'>
                    <h4 className='panel-title'>
                      <a
                        className='collapsed'
                        role='button'
                        data-toggle='collapse'
                        data-parent='#accordion'
                        href='#collapseAll'
                        aria-expanded='false'
                        aria-controls='collapseAll'
                      >
                        Data you will be sharing
                      </a>
                    </h4>
                  </div>
                  <div
                    id='collapseAll'
                    className='panel-collapse-all collapse'
                    role='tabpanel'
                    aria-labelledby='headingAll'
                  >
                    <div className='panel-body-all'>
                      <div className='panel panel-default'>
                        <div
                          className='panel-heading'
                          role='tab'
                          id='headingOne'
                        >
                          <h4 className='panel-title'>
                            <a
                              className='collapsed'
                              role='button'
                              data-toggle='collapse'
                              data-parent='#panel-body-all'
                              href='#collapseOne'
                              aria-expanded='false'
                              aria-controls='collapseOne'
                            >
                              Your Account Details
                            </a>
                          </h4>
                        </div>
                        <div
                          id='collapseOne'
                          className='panel-collapse collapse'
                          role='tabpanel'
                          aria-labelledby='headingOne'
                        >
                          <div className='panel-body'>
                            <ul
                              id='consent-list'
                              className='consent-info-item-list'
                            >
                              <li>Your account name, number and sort code</li>
                              <li>Your account balance</li>
                              <li>
                                Any other name by which you refer to this
                                account
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='panel panel-default'>
                        <div
                          className='panel-heading'
                          role='tab'
                          id='headingTwo'
                        >
                          <h4 className='panel-title'>
                            <a
                              className='collapsed'
                              role='button'
                              data-toggle='collapse'
                              data-parent='#panel-body-all'
                              href='#collapseTwo'
                              aria-expanded='false'
                              aria-controls='collapseTwo'
                            >
                              Your Account Transactions
                            </a>
                          </h4>
                        </div>
                        <div
                          id='collapseTwo'
                          className='panel-collapse collapse'
                          role='tabpanel'
                          aria-labelledby='headingTwo'
                        >
                          <div className='panel-body'>
                            <ul
                              id='consent-list'
                              className='consent-info-item-list'
                            >
                              <li>
                                Your incoming and outgoing transactions for the
                                last 12 months
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='panel panel-default'>
                        <div
                          className='panel-heading'
                          role='tab'
                          id='headingThree'
                        >
                          <h4 className='panel-title'>
                            <a
                              className='collapsed'
                              role='button'
                              data-toggle='collapse'
                              data-parent='#panel-body-all'
                              href='#collapseThree'
                              aria-expanded='false'
                              aria-controls='collapseThree'
                            >
                              Your Account Features and Benefits
                            </a>
                          </h4>
                        </div>
                        <div
                          id='collapseThree'
                          className='panel-collapse collapse'
                          role='tabpanel'
                          aria-labelledby='headingThree'
                        >
                          <div className='panel-body'>
                            <ul
                              id='consent-list'
                              className='consent-info-item-list'
                            >
                              <li>The type of account you have</li>
                              <li>The fees, charges and interest you pay</li>
                              <li>
                                The benefits, services, rewards and interest
                                your account offers
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='panel panel-default'>
                        <div
                          className='panel-heading'
                          role='tab'
                          id='headingFour'
                        >
                          <h4 className='panel-title'>
                            <a
                              className='collapsed'
                              role='button'
                              data-toggle='collapse'
                              data-parent='#panel-body-all'
                              href='#collapseFour'
                              aria-expanded='false'
                              aria-controls='collapseFour'
                            >
                              Your Regular Payments
                            </a>
                          </h4>
                        </div>
                        <div
                          id='collapseFour'
                          className='panel-collapse collapse'
                          role='tabpanel'
                          aria-labelledby='headingFour'
                        >
                          <div className='panel-body'>
                            <ul
                              id='consent-list'
                              className='consent-info-item-list'
                            >
                              <li>Your direct debits</li>
                              <li>Your standing orders</li>
                              <li>Other payee agreements you have set up</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p id='consent-info' className='consent-info-card'>
                {appName} will access your account information from your
                account(s) until: <strong>{accessUntil}</strong>
              </p>
            </div>

            <hr />

            <button
              className='btn btn-lg btn-primary btn-block btn-signin'
              type='submit'
              name='submit'
              value='yes'
            >
              {action}
            </button>

            <button
              className='btn btn-lg btn-primary btn-block btn-cancel'
              type='submit'
              name='submit'
              value='no'
            >
              Decline
            </button>
          </form>
        </div>
      </div>
    </Common>
  );
}
