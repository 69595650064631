import React from 'react';
import './App.css';

interface CommonProps {}

const Common: React.FunctionComponent<CommonProps> = (props: any) => {
  return (
    <div>
      <nav className='navbar navbar-default'>
        <div className='container-fluid'>
          <div className='navbar-header'>
            <a className='navbar-brand' href='/'>
              Demo Digital Wallet
            </a>
          </div>
        </div>
      </nav>
      <div>{props.children}</div>
    </div>
  );
};

export default Common;
