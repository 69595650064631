import axios from "axios";

const instance = axios.create({
  baseURL: "http://localhost:8083",
  headers: {
    "Content-Type": "application/json",
  },
});

const sendLog = (error: any) => {
  instance.post("/sendLog", {
    event: error,
  });
};

export { sendLog };
