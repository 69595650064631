import React, { useState, useEffect } from "react";
import Common from "./Common";
import "./App.css";

const defaultApplicationConfig = {
  clientId: "JgheOvwbyofPmZukCNNj8yGki60RQp47",
  clientSecret: "ZGIdx3fTAO4er8q6",
  redirectUrl: `${window.location.protocol}//${window.location.host}`,
  generateToken: "true",
};

const defaultAccounts: Account[] = [];

const App = (props: any) => {
  const [accounts, setAccounts] = useState([]);
  const [balances, setBalances] = useState([]);
  const [accessToken, setAccessToken] = useState<String | null>(null);
  const [generateToken, setGenerateToken] = useState<String>("false");
  const [applicationConfig, setApplicationConfig] = useState(
    defaultApplicationConfig
  );

  useEffect(() => {
    const applicationConfigString = localStorage.getItem("applicationConfig");
    const applicationConfig = applicationConfigString
      ? JSON.parse(applicationConfigString)
      : defaultApplicationConfig;
    setApplicationConfig(applicationConfig);

    setAccessToken(localStorage.getItem("access_token"));
  }, []);

  useEffect(() => {
    // Redirect to oidc authentication/authorization
    const submit = window.location.search.match(/submit=([^&]*)/);
    if (submit && submit[1] === "yes") {
      const config = JSON.parse(
        localStorage.getItem("applicationConfig") || ""
      );

      window.location.href = `https://atbfinancial-nonprod-preprod.openatb.com/oauth/v2/authorize?response_type=code&client_id=${
        config.clientId
      }&nonce=${new Date().getTime()}&state=${
        "state" + new Date().getTime()
      }&response_type=code&scope=openid&redirect_uri=${config.redirectUrl}`;
    }
  }, []);

  useEffect(() => {
    const code = window.location.search.match(/code=([^&]*)/);
    const {
      clientId,
      clientSecret,
      redirectUrl,
      generateToken,
    } = applicationConfig;

    if (
      code &&
      code.length === 2 &&
      localStorage.getItem("access_token") === null &&
      generateToken === "true"
    ) {
      fetch("https://atbfinancial-nonprod-preprod.openatb.com/oauth/v2/token", {
        method: "POST",
        body: `grant_type=authorization_code&code=${code[1]}&client_id=${clientId}&client_secret=${clientSecret}&redirect_uri=${redirectUrl}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (!data.hasOwnProperty("access_token")) {
            alert(data.fault.faultstring);
          } else {
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("id_token", data.id_token);
            setAccessToken(data.access_token);
            loadData();
          }
        })
        .catch((err) => console.log(err));
    }
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    if (localStorage.getItem("access_token") !== null) {
      Promise.all([
        fetch(
          "https://atbfinancial-nonprod-preprod.openatb.com/open-banking/v3.1/accounts",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        ),
        fetch(
          "https://atbfinancial-nonprod-preprod.openatb.com/open-banking/v3.1/balances",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
        ),
      ])
        .then((resp) => Promise.all([resp[0].json(), resp[1].json()]))
        .then((data) => {
          setAccounts(data[0].Data.Account);
          setBalances(data[1].Data.Balance);
        });
    }
  };

  const inputClientIdChange = (e: any) => {
    setApplicationConfig({ ...applicationConfig, clientId: e.target.value });
  };

  const inputClientSecretChange = (e: any) => {
    setApplicationConfig({
      ...applicationConfig,
      clientSecret: e.target.value,
    });
  };

  const inputRedirectUrlChange = (e: any) => {
    setApplicationConfig({
      ...applicationConfig,
      redirectUrl: e.target.value,
    });
  };

  const handleSubmit = () => {
    localStorage.setItem(
      "applicationConfig",
      JSON.stringify(applicationConfig)
    );
    props.history.push("/consent");
  };

  const reset = () => {
    localStorage.clear();
    setAccounts([]);
    props.history.push("/");
  };

  const getBalance = (accountId: string) => {
    const balance = balances.find((b) => b["AccountId"] === accountId);
    return balance ? (balance as any).Amount.amount : 0;
  };

  return (
    <Common>
      {accounts && accounts.length > 0 ? (
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <ul className="list-group">
              {accounts.map((a: any, i: number) => (
                <li className="list-group-item" key={i}>
                  <div className="row">
                    <div className="col-md-8">Name: {a.Nickname}</div>
                    <div className="col-md-4">${getBalance(a.AccountId)}</div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="text-center">
              <input
                type="button"
                onClick={() => reset()}
                value="Logout"
                className="btn btn-default"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row text-left">
          <div className="col-md-6 col-md-offset-3">
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <div className="form-group">
                <label>Client ID</label>
                <input
                  type="text"
                  id="filled-basic"
                  className="form-control"
                  defaultValue={applicationConfig.clientId}
                  onChange={inputClientIdChange}
                />
              </div>
              <div className="form-group">
                <label>Client Secret</label>
                <input
                  type="text"
                  id="filled-basic"
                  className="form-control"
                  defaultValue={applicationConfig.clientSecret}
                  onChange={inputClientSecretChange}
                />
              </div>
              <div className="form-group">
                <label>Callback URL</label>
                <input
                  type="text"
                  id="filled-basic"
                  className="form-control"
                  defaultValue={applicationConfig.redirectUrl}
                  onChange={inputRedirectUrlChange}
                />
              </div>
              <div className="hidden">
                <input
                  type="checkbox"
                  onChange={(e: any) =>
                    setGenerateToken(JSON.stringify(e.target.checked))
                  }
                  value="true"
                  checked={generateToken === "true"}
                />{" "}
                Generate Token
              </div>
              <div>
                <input
                  type="submit"
                  value="Connect My Account"
                  className="btn btn-primary"
                />
                &nbsp;
                <input
                  type="button"
                  onClick={() => reset()}
                  value="Reset"
                  className="btn btn-default"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </Common>
  );
};

export default App;
